import React, {useRef} from 'react'
import {Routers} from "./routers/Routers";
import {SnackbarProvider, closeSnackbar} from "notistack";
import IconButton from "@mui/joy/IconButton";

function App() {
    const snackbarRef = useRef();

    return (
        <SnackbarProvider
            anchorOrigin={{horizontal: "center", vertical: "bottom"}}
            autoHideDuration={1000}
            preventDuplicate={true}
            ref={snackbarRef}
            // action={(snackbarId) => (
            //     <IconButton variant="outlined" size="sm"
            //                 onClick={() => closeSnackbar(snackbarId)}>
            //         <Icon name="X" size={"16px"}/>
            //     </IconButton>
            // )}
        >
            <Routers/>
        </SnackbarProvider>
    )
}

export default App
