import * as React from 'react';
import {UserCard} from "../components/UserCard";
import {Grid, CircularProgress, Typography, Box, Input} from "@mui/joy";
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {PaidLeadDialog} from "../dialog/PaidLeadDialog";

export function Dashboard() {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [success, setSuccess] = React.useState(false);
    const users = async () => {
        try {
            let config = {headers: {'Authorization': 'Bearer ' + token}}
            let {status, data: {data}} = await axios.get(`${WEB_API}admin/retrieve/dashboard`, config);
            if (status === 200) {
                setRows(data)
                setSuccess(true)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
            setSuccess(true)
        }
    };

    React.useEffect(() => {
        users()
    }, []);
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid xs={12} md={12}>
                    <Box >
                        <Grid container spacing={2} justifyContent="flex-end" sx={{m: 0, p: 0}}>
                            <Grid xs={12} md={2}>
                                Start Date <Input placeholder="Type in here…" type="datetime-local"/>
                            </Grid>
                            <Grid xs={12} md={2}>
                                End Date <Input placeholder="Type in here…" type="datetime-local"/>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {success ? rows?.map((user) => {
                        return (
                            <Grid xs={12} md={12}>
                                <UserCard user={user} setOpen={setOpen}/>
                            </Grid>
                        )
                    }) :
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "85vh",
                        width: "100%"
                    }}>
                        <Typography variant="h6" sx={{textAlign: "center"}}>
                            <CircularProgress variant="plain"/>
                        </Typography>
                    </Box>
                }
            </Grid>
            {open && <PaidLeadDialog open={open} onClose={() => setOpen(false)} user_id={open}/>}
        </React.Fragment>
    );
}