import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from "@mui/joy/ModalClose";
import Box from "@mui/joy/Box";

export function ImagePreview({open, onClose}) {
    return (
        <React.Fragment>
            <Modal open={open} onClose={onClose}>
                <ModalDialog layout={"fullscreen"} sx={{p: 0}}>
                    <ModalClose size="sm" variant="soft"/>
                    <Box sx={{width: "100%", height: "100%"}}>
                        <img
                            src={open}
                            loading="lazy"
                            alt=""
                            style={{objectFit: "contain", width: "100%", height: "100%"}}
                        />
                    </Box>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}