import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Grid from "@mui/joy/Grid";

export function UserCard({user, setOpen}) {
    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                overflow: {xs: 'auto', sm: 'initial'},
            }}
        >
            <Box/>
            <Card
                orientation="horizontal"
                sx={{
                    width: '100%',
                    flexWrap: 'wrap',
                    [`& > *`]: {
                        '--stack-point': '500px',
                        minWidth:
                            'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
                    },
                    // make the card resizable for demo
                    overflow: 'auto',
                    resize: 'horizontal',
                }}
            >
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid xs={12} md={12}>
                            <Grid container justifyContent="space-between">
                                <Grid xs={6} md={6}>
                                    <Typography fontSize="xl" fontWeight="lg">
                                        #{user?.id}
                                    </Typography>
                                    <Typography fontSize="xl" fontWeight="lg">
                                        {user?.name}
                                    </Typography>
                                    <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
                                        {user?.mobile}
                                    </Typography>
                                </Grid>
                                <Grid xs={6} md={6} >
                                    <Box sx={{display: 'flex', gap: 1.5, '& > button': {flex: 1}}}>
                                        <Button variant="soft" color="neutral" onClick={()=>setOpen(user?.id)}>
                                            Table View
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={3}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography level="lg" fontWeight="lg">
                                    Paid
                                </Typography>
                            </Box>
                            <Sheet
                                sx={{
                                    bgcolor: 'background.level1',
                                    borderRadius: 'sm',
                                    p: 1.5,
                                    my: 1.5,
                                    display: 'flex',
                                    gap: 2,
                                    '& > div': {flex: 1},
                                }}
                            >
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Total
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalPaidLeads || 0}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Converts
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalCompletedPaidLeads || 0}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Amount
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalPaidLeadsAmount || 0}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Conversation Ratio
                                    </Typography>
                                    <Typography fontWeight="lg">8.9%</Typography>
                                </div>
                            </Sheet>
                        </Grid>
                        <Grid xs={12} md={3}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography level="lg" fontWeight="lg">
                                    Failed
                                </Typography>
                            </Box>
                            <Sheet
                                sx={{
                                    bgcolor: 'background.level1',
                                    borderRadius: 'sm',
                                    p: 1.5,
                                    my: 1.5,
                                    display: 'flex',
                                    gap: 2,
                                    '& > div': {flex: 1},
                                }}
                            >
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Total
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalFailedLeads}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Converts
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalCompletedFailedLeads || 0}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Amount
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalFailedLeadsAmount || 0}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Conversation Ratio
                                    </Typography>
                                    <Typography fontWeight="lg">8.9%</Typography>
                                </div>
                            </Sheet>
                        </Grid>
                        <Grid xs={12} md={3}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography level="lg" fontWeight="lg">
                                    Created
                                </Typography>
                            </Box>
                            <Sheet
                                sx={{
                                    bgcolor: 'background.level1',
                                    borderRadius: 'sm',
                                    p: 1.5,
                                    my: 1.5,
                                    display: 'flex',
                                    gap: 2,
                                    '& > div': {flex: 1},
                                }}
                            >
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Total
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalCreatedLeads}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Converts
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalCompletedCreatedLeads || 0}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Amount
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalCreatedLeadsAmount || 0}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Conversation Ratio
                                    </Typography>
                                    <Typography fontWeight="lg">8.9%</Typography>
                                </div>
                            </Sheet>
                        </Grid>
                        <Grid xs={12} md={3}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography level="lg" fontWeight="lg">
                                    Unpaid
                                </Typography>
                            </Box>
                            <Sheet
                                sx={{
                                    bgcolor: 'background.level1',
                                    borderRadius: 'sm',
                                    p: 1.5,
                                    my: 1.5,
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 2,
                                    '& > div': {flex: 1},
                                }}
                            >
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Total
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalUnpaidLeads}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Converts
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalCompletedUnpaidLeads || 0}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Amount
                                    </Typography>
                                    <Typography fontWeight="lg">{user?.totalUnpaidLeadsAmount || 0}</Typography>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Conversation Ratio
                                    </Typography>
                                    <Typography fontWeight="lg">8.9%</Typography>
                                </div>
                            </Sheet>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}