import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Autocomplete from '@mui/joy/Autocomplete';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";
import DialogActions from "@mui/joy/DialogActions";
import Button from "@mui/joy/Button";
import {useNavigate} from "react-router-dom";

export function AssignToDialog({open, onClose, selectedRows, rerender}) {
    const navigate = useNavigate();
    const token = localStorage.getItem("token")
    const [users, setUsers] = React.useState([])
    const [select, setSelect] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    console.log(select, "<<<<<<<<<<<<<<<<<<<select>>>>>>>>>>>>>>>>>>>")
    const saveUser = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let {status, data: {message}} = await axios.post(`${WEB_API}admin/create/assign-leads`, {
                leads: selectedRows,
                assignTo: select
            }, config);
            if (status === 200 || 201) {
                enqueueSnackbar(`${message}`, {variant: 'success'})
                setIsLoading(false);
                onClose()
                rerender(open);
            }
        } catch (e) {
            enqueueSnackbar(`${e.response?.data?.message}`, {variant: 'error'})
            setIsLoading(false)
        }
    };

    const AllUsers = async () => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let {status, data} = await axios.get(`${WEB_API}admin/retrieve/clients`, config);
            if (status === 200) {
                setUsers(data?.data)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
        }
    };

    React.useEffect(() => {
        AllUsers()
    }, []);


    return (
        <React.Fragment>
            <Modal open={open} onClose={onClose}>
                <ModalDialog minWidth={"small"} sx={{height: "400px"}}>
                    <DialogTitle>Select User</DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <FormLabel>Label</FormLabel>
                            <Autocomplete
                                placeholder="Placeholder"
                                getOptionLabel={(option) => option.name}
                                options={users}
                                onChange={(event, newValue) => setSelect(newValue)}
                                slotProps={{
                                    listbox: {
                                        sx: {
                                            maxHeight: "200px"
                                        }
                                    }
                                }}
                                open
                                sx={{width: 300}}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="soft" loading={isLoading} color="success" onClick={(e) => saveUser(e)}>
                            Assign
                        </Button>
                        <Button variant="plain" color="danger" onClick={onClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}