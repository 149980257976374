import React from "react";
import {Navigate, Outlet} from "react-router-dom";

const ProtectedRoute = () => {

    const token = localStorage.getItem("token");
    if (token) {
        return <Outlet/>;
    } else {
        return <Navigate to="/sign-in"/>;
    }
};

export default ProtectedRoute;
