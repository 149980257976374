import * as React from 'react';
import {useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import {useColorScheme} from "@mui/joy/styles";
import {ChartDialog} from "../dialog/ChartDialog";
import {Box, Grid, Input} from "@mui/joy";

export function BarChart({data}) {
    const {mode} = useColorScheme();
    const [open, setOpen] = useState({open: false, data: ''});
    const theme = mode === "dark" ? am5themes_Dark : am5themes_Animated

    // Function to handle click event on series
    const handleColumnClick = (event) => {
        const dataItem = event.target.dataItem;
        if (dataItem) {
            const category = dataItem.dataContext.state;
            const value = dataItem.dataContext.sales;
            setOpen({open: true, data: `Category: ${category}, Value: ${value}`})
            // console.log(`Category: ${category}, Value: ${value}`);
            // Call your custom function here
            // customFunction(category, value);
        }
    };

    useLayoutEffect(() => {
        let root = am5.Root.new("chartdiv");

        root.setThemes([
            theme.new(root)
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            layout: root.horizontalLayout,
            paddingLeft: 0
        }));

        let legendData = [];
        let legend = chart.children.push(
            am5.Legend.new(root, {
                nameField: "name",
                fillField: "color",
                strokeField: "color",
                //centerY: am5.p50,
                marginLeft: 20,
                y: 20,
                layout: root.verticalLayout,
                clickTarget: "none"
            })
        );

        let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "state",
            renderer: am5xy.AxisRendererY.new(root, {
                minGridDistance: 10,
                minorGridEnabled: true,
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        yAxis.get("renderer").labels.template.setAll({
            fontSize: 12,
            location: 0.5,
        })

        yAxis.data.setAll(data);

        let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "sales",
            categoryYField: "state",
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal"
            })
        }));

        series.columns.template.setAll({
            tooltipText: "{categoryY}: [bold]{valueX}[/]",
            width: am5.percent(90),
            strokeOpacity: 0
        });

        // Add event listener for click on series columns
        series.columns.template.events.on("click", handleColumnClick);

        series.columns.template.adapters.add("fill", function (fill, target) {
            if (target.dataItem) {
                switch (target.dataItem.dataContext.region) {
                    case "Central":
                        return chart.get("colors").getIndex(0);
                        break;
                    case "East":
                        return chart.get("colors").getIndex(1);
                        break;
                    case "South":
                        return chart.get("colors").getIndex(2);
                        break;
                    case "West":
                        return chart.get("colors").getIndex(3);
                        break;
                }
            }
            return fill;
        })

        series.data.setAll(data);

        function createRange(label, category, color) {
            let rangeDataItem = yAxis.makeDataItem({
                category: category
            });

            let range = yAxis.createAxisRange(rangeDataItem);

            rangeDataItem.get("label").setAll({
                fill: color,
                text: label,
                location: 1,
                fontWeight: "bold",
                dx: -130
            });

            rangeDataItem.get("grid").setAll({
                stroke: color,
                strokeOpacity: 1,
                location: 1
            });

            rangeDataItem.get("tick").setAll({
                stroke: color,
                strokeOpacity: 1,
                location: 1,
                visible: true,
                length: 130
            });

            legendData.push({name: label, color: color});
        }

        createRange("Central", "Texas", chart.get("colors").getIndex(0));
        createRange("East", "New York", chart.get("colors").getIndex(1));
        createRange("South", "Florida", chart.get("colors").getIndex(2));
        createRange("West", "California", chart.get("colors").getIndex(3));

        legend.data.setAll(legendData);

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis
        }));

        series.appear();
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [mode, data]);
    return (<React.Fragment>
            <Box>
                <Grid container spacing={2} justifyContent="flex-end" sx={{m: 0, p: 0}}>
                    <Grid xs={12} md={2}>
                        Start Date <Input placeholder="Type in here…" type="datetime-local"/>
                    </Grid>
                    <Grid xs={12} md={2}>
                        End Date <Input placeholder="Type in here…" type="datetime-local"/>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{height: "80vh", overflow: "auto"}}>
                <div id="chartdiv" style={{width: "100%", height:"100%", overflow: "auto"}}></div>
            </Box>
            {open.open &&
            <ChartDialog open={open.open} onClose={() => setOpen({open: false, data: ''})} data={open?.data}/>}
        </React.Fragment>
    );
}