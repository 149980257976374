import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from "@mui/joy/ModalClose";
import Box from "@mui/joy/Box";
import {BarChart2} from "../components/BarChart2";

export function ChartDialog({open, onClose, data}) {
    return (
        <React.Fragment>
            <Modal open={open} onClose={onClose}>
                <ModalDialog layout={"fullscreen"} sx={{p: 0}}>
                    <ModalClose size="sm" variant="soft"/>
                    <DialogTitle>{data}</DialogTitle>
                    <DialogContent>
                        <Box sx={{width: "100%", height: "100%", padding:"40px"}}>
                            <BarChart2/>
                        </Box>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}