import * as React from 'react';
import {useColorScheme} from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import ScoreIcon from '@mui/icons-material/Score';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ReplayIcon from '@mui/icons-material/Replay';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import {CurrencyRupee, Dashboard, GroupAdd, TrendingDown, TrendingUp} from '@mui/icons-material';
import {Link, useNavigate} from 'react-router-dom'
import Navigation from './Navigations';
import {DebouncedInput} from "./DebounceInput";
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";

function ColorSchemeToggle() {
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="primary"/>;
    }
    return (
        <Box>
            <IconButton
                id="toggle-mode"
                size="sm"
                variant="plain"
                color="neutral"
                sx={{alignSelf: 'center'}}
                onClick={() => {
                    if (mode === 'light') {
                        setMode('dark');
                    } else {
                        setMode('light');
                    }
                }}
            >
                {mode === 'light' ? <DarkModeRoundedIcon style={{marginRight: "7px"}}/> :
                    <LightModeRoundedIcon style={{marginRight: "7px"}}/>}
                {mode === 'light' ? " " + "Dark Mode" : " " + "Light Mode"}
            </IconButton>
        </Box>
    );
}

export default function Header({setDebouncedValue}) {
    const navigate = useNavigate()
    const token = localStorage.getItem("token");
    const loginUser = JSON.parse(localStorage.getItem("user"));
    const [open, setOpen] = React.useState(false);
    const activeValue = localStorage.getItem("activeList");

    const handleActiveList = (listValue) => {
        return +activeValue === listValue
    }
    const handleChange = (value) => {
        localStorage.setItem("activeList", value)
    }

    const logout = async () => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let {status, data: {message}} = await axios.delete(`${WEB_API}admin/auth/logout`, config);
            console.log(message, status)
            if (status === 200) {
                navigate("/sign-in");
                localStorage.clear()
                enqueueSnackbar(`${message}`, {variant: 'success'})
            }
        } catch (e) {
            console.log(".............")
            if (e.response?.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response?.data?.message}`, {variant: 'error'})
        }
    };

    const reload = () => {
        window.location.reload();
    }

    return (
        <Box
            sx={{
                width: "100%",
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
                padding: "20px",
                borderBottom: "1px solid #dde7ee"
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{display: {xs: 'none', sm: 'flex'}}}
            >
                <ScoreIcon sx={{fontSize: 35, color: "rgb(216, 91, 83)"}}/>
                <Link to="/dashboard" onClick={() => handleChange(1)}>
                    <Button
                        variant="plain"
                        color="neutral"
                        aria-pressed={handleActiveList(1)}
                        size="sm"
                        sx={{alignSelf: 'center'}}
                        startDecorator={<Dashboard/>}
                    >
                        Dashboard
                    </Button>
                </Link>
                <Link to="/users" onClick={() => handleChange(2)}>
                    <Button
                        variant="plain"
                        color="neutral"
                        aria-pressed={handleActiveList(2)}
                        size="sm"
                        sx={{alignSelf: 'center'}}
                        startDecorator={<GroupAdd/>}
                    >
                        Users
                    </Button>
                </Link>
                <Link to="/leads" onClick={() => handleChange(3)}>
                    <Button
                        variant="plain"
                        color="neutral"
                        aria-pressed={handleActiveList(3)}
                        size="sm"
                        sx={{alignSelf: 'center'}}
                        startDecorator={<Diversity3Icon/>}
                    >
                        Leads
                    </Button>
                </Link>
                <Link to="/payment-details" onClick={() => handleChange(4)}>
                    <Button
                        variant="plain"
                        color="neutral"
                        aria-pressed={handleActiveList(4)}
                        size="sm"
                        sx={{alignSelf: 'center'}}
                        startDecorator={<CurrencyRupee/>}
                    >
                        Payment Details
                    </Button>
                </Link>
                {/*<Link to="/unpaid-up" onClick={() => handleChange(5)}>*/}
                {/*    <Button*/}
                {/*        variant="plain"*/}
                {/*        color="success"*/}
                {/*        aria-pressed={handleActiveList(5)}*/}
                {/*        size="sm"*/}
                {/*        sx={{alignSelf: 'center'}}*/}
                {/*        startDecorator={<TrendingUp/>}*/}
                {/*    >*/}
                {/*        Unpaid*/}
                {/*    </Button>*/}
                {/*</Link>*/}
                {/*<Link to="/unpaid-down" onClick={() => handleChange(4)}>*/}
                {/*    <Button*/}
                {/*        variant="plain"*/}
                {/*        color="danger"*/}
                {/*        aria-pressed={handleActiveList(4)}*/}
                {/*        size="sm"*/}
                {/*        sx={{alignSelf: 'center'}}*/}
                {/*        startDecorator={<TrendingDown/>}*/}
                {/*    >*/}
                {/*        Unpaid*/}
                {/*    </Button>*/}
                {/*</Link>*/}
            </Stack>
            <Box sx={{display: {xs: 'inline-flex', sm: 'none'}}}>
                <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
                    <MenuRoundedIcon/>
                </IconButton>
                <Drawer
                    sx={{display: {xs: 'inline-flex', sm: 'none'}}}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <ModalClose/>
                    <DialogTitle>WA Analysis</DialogTitle>
                    <Box sx={{px: 1}}>
                        <Navigation/>
                    </Box>
                </Drawer>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                    alignItems: 'center',
                }}
            >
                <DebouncedInput setDebouncedValue={setDebouncedValue}/>
                <Tooltip title="Refresh">
                    <IconButton onClick={reload}>
                        <ReplayIcon/>
                    </IconButton>
                </Tooltip>

                <Dropdown>
                    <MenuButton
                        variant="plain"
                        size="sm"
                        sx={{maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px'}}
                    >
                        <Avatar
                            src=""
                            srcSet=""
                            sx={{maxWidth: '32px', maxHeight: '32px'}}
                        />
                    </MenuButton>
                    <Menu
                        placement="bottom-end"
                        size="sm"
                        sx={{
                            zIndex: '99999',
                            p: 1,
                            gap: 1,
                            '--ListItem-radius': 'var(--joy-radius-sm)',
                        }}
                    >
                        <MenuItem>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar
                                    src=""
                                    srcSet=""
                                    sx={{borderRadius: '50%'}}
                                />
                                <Box sx={{ml: 1.5}}>
                                    <Typography level="title-sm" textColor="text.primary">
                                        {loginUser.name}
                                    </Typography>
                                    <Typography level="body-xs" textColor="text.tertiary">
                                        {loginUser.mobile}
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                        <ListDivider/>
                        <MenuItem>
                            <ColorSchemeToggle/>
                        </MenuItem>
                        <MenuItem>
                            <SettingsRoundedIcon/>
                            Settings
                        </MenuItem>
                        <ListDivider/>
                        <MenuItem onClick={logout} color="danger">
                            <LogoutRoundedIcon/>
                            Log out
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </Box>
        </Box>
    );
}