import * as React from 'react';
import Grid from "@mui/joy/Grid";
import {BarChart} from "../components/BarChart";
import {useEffect, useState} from "react";

let data = [
    {
        region: "Central",
        state: "North Dakota",
        sales: 920
    }, {
        region: "Central",
        state: "South Dakota",
        sales: 1317
    }, {
        region: "Central",
        state: "Kansas",
        sales: 2916
    }, {
        region: "Central",
        state: "Iowa",
        sales: 4577
    }, {
        region: "Central",
        state: "Nebraska",
        sales: 7464
    }, {
        region: "Central",
        state: "Oklahoma",
        sales: 19686
    }, {
        region: "Central",
        state: "Missouri",
        sales: 22207
    }, {
        region: "Central",
        state: "Minnesota",
        sales: 29865
    }, {
        region: "Central",
        state: "Wisconsin",
        sales: 32125
    }, {
        region: "Central",
        state: "Indiana",
        sales: 53549
    }, {
        region: "Central",
        state: "Michigan",
        sales: 76281
    }, {
        region: "Central",
        state: "Illinois",
        sales: 80162
    }, {
        region: "Central",
        state: "Texas",
        sales: 170187
    }, {
        region: "East",
        state: "West Virginia",
        sales: 1209
    }, {
        region: "East",
        state: "Maine",
        sales: 1270
    }, {
        region: "East",
        state: "District of Columbia",
        sales: 2866
    }, {
        region: "East",
        state: "New Hampshire",
        sales: 7294
    }, {
        region: "East",
        state: "Vermont",
        sales: 8929
    }, {
        region: "East",
        state: "Connecticut",
        sales: 13386
    }, {
        region: "East",
        state: "Rhode Island",
        sales: 22629
    }, {
        region: "East",
        state: "Maryland",
        sales: 23707
    }, {
        region: "East",
        state: "Delaware",
        sales: 27453
    }, {
        region: "East",
        state: "Massachusetts",
        sales: 28639
    }, {
        region: "East",
        state: "New Jersey",
        sales: 35763
    }, {
        region: "East",
        state: "Ohio",
        sales: 78253
    }, {
        region: "East",
        state: "Pennsylvania",
        sales: 116522
    }, {
        region: "East",
        state: "New York",
        sales: 310914
    }, {
        region: "South",
        state: "South Carolina",
        sales: 8483
    }, {
        region: "South",
        state: "Louisiana",
        sales: 9219
    }, {
        region: "South",
        state: "Mississippi",
        sales: 10772
    }, {
        region: "South",
        state: "Arkansas",
        sales: 11678
    }, {
        region: "South",
        state: "Alabama",
        sales: 19511
    }, {
        region: "South",
        state: "Tennessee",
        sales: 30662
    }, {
        region: "South",
        state: "Kentucky",
        sales: 36598
    }, {
        region: "South",
        state: "Georgia",
        sales: 49103
    }, {
        region: "South",
        state: "North Carolina",
        sales: 55604
    }, {
        region: "South",
        state: "Virginia",
        sales: 70641
    }, {
        region: "South",
        state: "Florida",
        sales: 89479
    }, {
        region: "West",
        state: "Wyoming",
        sales: 1603
    }, {
        region: "West",
        state: "Idaho",
        sales: 4380
    }, {
        region: "West",
        state: "New Mexico",
        sales: 4779
    }, {
        region: "West",
        state: "Montana",
        sales: 5589
    }, {
        region: "West",
        state: "Utah",
        sales: 11223
    }, {
        region: "West",
        state: "Nevada",
        sales: 16729
    }, {
        region: "West",
        state: "Oregon",
        sales: 17431
    }, {
        region: "West",
        state: "Colorado",
        sales: 32110
    }, {
        region: "West",
        state: "Arizona",
        sales: 35283
    }, {
        region: "West",
        state: "Washington",
        sales: 138656
    }, {
        region: "West",
        state: "California",
        sales: 457731
    }];

export function Analysis() {

    return (
        <React.Fragment>
            <Grid container>
                <Grid xs={12} md={12}>
                    <BarChart data={data}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}