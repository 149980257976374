import {io} from "socket.io-client";
import {WEB_API_SOCKET} from "../constants";

const loginUser = JSON.parse(localStorage.getItem("user"));

const socket = io(WEB_API_SOCKET, {
    autoConnect: true,
    auth: {
        username: loginUser?.id || ""
    },
})


socket.on("connection", () => {
    // console.log(socket.connected); // true
    // console.log(socketId)
});

socket.onAny((event, ...arg) => {
    console.log(event, arg,"ssssssssssss"); // false
});


export default socket;