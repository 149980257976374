import React from "react";
import {Grid, IconButton, Chip, Box, Divider, Input, Typography, Stack, Select, Option} from "@mui/joy";
import axios from "axios";
import {WEB_API, WEB_API_IMAGE} from "../constants";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {CurrencyRupee, Search} from '@mui/icons-material';
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs";

dayjs.extend(utc);
import {styled} from '@mui/joy/styles';
import Sheet from "@mui/joy/Sheet";
import {DynamicTable} from "../components/DynamicTable";
import AspectRatio from "@mui/joy/AspectRatio";
import {ImagePreview} from "../dialog/ImagePreview";

const Item = styled(Sheet)(({theme}) => ({
    ...theme.typography['body-lg'],
    textAlign: 'center',
    fontWeight: theme.fontWeight.md,
    color: theme.vars.palette.text.secondary,
    border: '1px solid',
    borderColor: theme.palette.divider,
    padding: theme.spacing(2),
    borderRadius: theme.radius.md,
    flexGrow: 1,
}));

export const PaymentDetails = () => {
    const navigate = useNavigate()
    const token = localStorage.getItem("token")
    let config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    const [paidAmount, setPaidAmount] = React.useState(0);
    const [conversationAmount, setConversationAmount] = React.useState(0);
    const [date, setDate] = React.useState(dayjs(new Date()).format("YYYY-MM-DD"));
    const [payment, setPayment] = React.useState({rows: [], total: 0})
    const [page, setPage] = React.useState(0);
    const [limit, setLimit] = React.useState(10);
    const [preview, setPreview] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [assignTo, setAssignTo] = React.useState('');
    const [users, setUsers] = React.useState([])
    console.log(assignTo,"assignToassignTo")
    const handlePagination = (key, page, limit) => {
        setPage(page);
        setLimit(limit);
    }
    const columns = [
        {id: 'id', label: 'ID', align: 'center', minWidth: 70},
        {id: 'lead_name', label: 'Lead Name', align: 'center', minWidth: 70},
        {
            id: 'user_name',
            label: 'Assignee Name',
            minWidth: 70,
            align: 'center',
            renderCell: (parms) => {
                return (
                    <Box>
                        {parms?.user_name ?
                            <Chip sx={{
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} variant="outlined" color="primary">{parms?.user_name}</Chip> : "NA"}
                    </Box>
                )
            }
        },
        {
            id: 'screenshot',
            label: 'Screenshot',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                const url = WEB_API_IMAGE + params?.screenshot
                return <Box>
                    {params?.screenshot && <IconButton onClick={() => setPreview(url)}>
                        <AspectRatio ratio="1" sx={{width: 40}}>
                            <img
                                src={WEB_API_IMAGE + params?.screenshot}
                                srcSet={`${WEB_API_IMAGE + params?.screenshot} 2x`}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </IconButton> || "NA"}
                </Box>
            }
        },
        {
            id: 'amount',
            label: 'Amount',
            minWidth: 50,
            align: 'center',
            renderCell: (params) => {
                return <Chip
                    variant="outlined"
                >
                    {params.amount}
                </Chip>
            }
        },
        {
            id: 'createdAt',
            label: 'Created At',
            minWidth: 100,
            align: 'center',
            renderCell: (parms) => {
                return <Box>{dayjs(`${parms?.createdAt}`).format("YYYY-MM-DD hh:mm A")}</Box>
            }
        }
    ];
    const paymentDetails = async () => {
        setSuccess(true)
        try {
            let {status, data: {data, type}} = await axios.get(`${WEB_API}admin/retrieve/payment-details?user_id=${assignTo}&limit=${limit}&page=${page}`, config);
            if (status === 200 && type === "success") {
                setPayment({rows: data?.payments, total: data?.total})
                setSuccess(false)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
            setSuccess(false)
        }
    };

    React.useEffect(() => {
        paymentDetails()
    }, [assignTo, limit, page]);

    const PaidLeadsAmount = async () => {
        try {
            let {status, data: {data, type}} = await axios.get(`${WEB_API}admin/retrieve/paid-leads-amount?date=${date}`, config);
            if (status === 200 && type === "success") {
                setPaidAmount(data?.amount)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
        }
    };

    const LeadsConversationAmount = async () => {
        try {
            let {status, data: {data, type}} = await axios.get(`${WEB_API}admin/retrieve/leads-conversation-amount?date=${date}`, config);
            if (status === 200 && type === "success") {
                setConversationAmount(data?.amount)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
        }
    };

    const AllUsers = async () => {
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let {status, data} = await axios.get(`${WEB_API}admin/retrieve/clients`, config);
            if (status === 200) {
                setUsers(data?.data)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
        }
    };

    React.useEffect(() => {
        AllUsers()
    }, []);

    React.useEffect(() => {
        PaidLeadsAmount()
        LeadsConversationAmount()
    }, [date])
    return (
        <Box sx={{flex: 1, width: '100%'}}>
            <Grid container spacing={2}>
                <Grid xs={12} md={12}>
                    <Grid container justifyContent="space-between" sx={{margin: "10px"}}>
                        <Grid xs={12} md={4}><Typography level="h3">Payment Details</Typography></Grid>
                        <Grid xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={4}>
                                    <Input
                                        type="text"
                                        placeholder="Search by mobile"
                                        endDecorator={<IconButton><Search/></IconButton>}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <Stack spacing={2} sx={{alignItems: 'flex-start'}}>
                                        <Select
                                            placeholder="Search by assign user"
                                            name="assignTo"
                                            required
                                            sx={{minWidth: 200}}
                                            onChange={(e, value) => setAssignTo(value)}
                                        >
                                            {users && users.map((item) => {
                                                return <Option value={item?.id}>{item?.name}</Option>
                                            })}
                                        </Select>
                                    </Stack>
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <Input
                                        type="date"
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={4}>
                    <Item>Paid Leads Amount:-> <CurrencyRupee fontSize="16px"/>{paidAmount || 0}</Item>
                </Grid>
                <Grid xs={12} md={4}>
                    <Item>Leads Conversation Amount:-> <CurrencyRupee fontSize="16px"/>{conversationAmount || 0}</Item>
                </Grid>
                <Grid xs={12} md={4}>
                    <Item>Total Amount:-> <CurrencyRupee fontSize="16px"/>{paidAmount + conversationAmount}</Item>
                </Grid>
                <Grid xs={12} md={6}>
                    <DynamicTable hadName="UnVerified Payment" maxHeight="640px" columns={columns}
                                  count={payment?.total} rows={payment?.rows} perPage={limit}
                                  success={!success} checkBox={false} showHad={true} showOnlyHadName={true}
                                  setPagination={handlePagination}/>
                </Grid>
                <Grid xs={12} md={6}>
                    <DynamicTable hadName="Verified Payment" maxHeight="640px" columns={columns} count={payment?.total}
                                  rows={payment?.rows} perPage={limit}
                                  success={!success} checkBox={false} showHad={true} showOnlyHadName={true}
                                  setPagination={handlePagination}/>
                </Grid>
            </Grid>
            <ImagePreview open={preview} onClose={() => setPreview(false)}/>
        </Box>
    )
}