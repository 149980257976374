import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Header from '../components/Header';
import {Outlet} from "react-router-dom";
import Grid from "@mui/joy/Grid";

export function Main({setDebouncedValue}) {
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Grid container sx={{m:0,p:0}}>
                <Grid xs={12} md={12}>
                    <Header setDebouncedValue={setDebouncedValue}/>
                </Grid>
                <Grid xs={12} md={12}>
                    <Box
                        sx={{
                            pt: 0,
                            pb: 0,
                            py:"12px",
                            px:"15px",
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 0,
                            height: '90dvh',
                            gap: 1,
                            overflow: 'auto',
                        }}
                    >
                        <Outlet/>
                    </Box>
                </Grid>
            </Grid>
        </CssVarsProvider>
    );
}