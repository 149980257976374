import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import axios from "axios";
import {WEB_API, WEB_API_IMAGE} from "../constants";
import {enqueueSnackbar} from "notistack";
import DialogActions from "@mui/joy/DialogActions";
import Button from "@mui/joy/Button";
import {useNavigate} from "react-router-dom";
import {DynamicTable} from "../components/DynamicTable";
import {Box, Chip} from "@mui/joy";
import dayjs from "dayjs";
import AspectRatio from "@mui/joy/AspectRatio";
import IconButton from "@mui/joy/IconButton";
import {ImagePreview} from "./ImagePreview";

export function PaidLeadDialog({open, onClose, user_id}) {
    const navigate = useNavigate();
    const token = localStorage.getItem("token")
    const [payment, setPayment] = React.useState({rows: [], total: 0})
    const [page, setPage] = React.useState(0);
    const [limit, setLimit] = React.useState(10);
    const [preview, setPreview] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    console.log(user_id)

    const handlePagination = (key, page, limit) => {
        setPage(page);
        setLimit(limit);
    }
    const columns = [
        {id: 'id', label: 'ID', align: 'center', minWidth: 70},
        {id: 'lead_name', label: 'Lead Name', align: 'center', minWidth: 70},
        {
            id: 'user_name',
            label: 'Assignee Name',
            minWidth: 70,
            align: 'center',
            renderCell: (parms) => {
                return (
                    <Box>
                        {parms?.user_name ?
                            <Chip sx={{
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} variant="outlined" color="primary">{parms?.user_name}</Chip> : "NA"}
                    </Box>
                )
            }
        },
        {
            id: 'screenshot',
            label: 'Screenshot',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                const url = WEB_API_IMAGE + params?.screenshot
                return <Box>
                    {params?.screenshot && <IconButton onClick={() => setPreview(url)}>
                        <AspectRatio ratio="1" sx={{width: 80}}>
                            <img
                                src={WEB_API_IMAGE + params?.screenshot}
                                srcSet={`${WEB_API_IMAGE + params?.screenshot} 2x`}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </IconButton> || "NA"}
                </Box>
            }
        },
        {
            id: 'amount',
            label: 'Amount',
            minWidth: 50,
            align: 'center',
            renderCell: (params) => {
                return <Chip
                    variant="outlined"
                >
                    {params.amount}
                </Chip>
            }
        },
        {
            id: 'createdAt',
            label: 'Created At',
            minWidth: 100,
            align: 'center',
            renderCell: (parms) => {
                return <Box>{dayjs(`${parms?.createdAt}`).format("YYYY-MM-DD hh:mm")}</Box>
            }
        }
    ];

    let config = {headers: {'Authorization': 'Bearer ' + token}};
    const paymentDetails = async () => {
        setSuccess(true)
        try {
            let {status, data: {data, type}} = await axios.get(`${WEB_API}admin/retrieve/payment-details?user_id=${user_id}&limit=${limit}&page=${page}`, config);
            if (status === 200 && type === "success") {
                setPayment({rows: data?.payments, total: data?.total})
                setSuccess(false)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
            setSuccess(false)
        }
    };

    React.useEffect(() => {
        paymentDetails()
    }, [user_id, limit, page]);


    return (
        <React.Fragment>
            <Modal open={open} onClose={onClose}>
                <ModalDialog size="md">
                    <DialogTitle>Payment Details</DialogTitle>
                    <DialogContent>
                        <DynamicTable columns={columns} count={payment?.total} rows={payment?.rows} perPage={limit}
                                      success={!success} checkBox={false} showHad={false}
                                      setPagination={handlePagination}/>
                    </DialogContent>
                    <DialogActions>
                        <Box>
                            <Button variant="soft" color="danger" onClick={onClose}>
                                Close
                            </Button>
                        </Box>
                    </DialogActions>
                </ModalDialog>
            </Modal>
            <ImagePreview open={preview} onClose={() => setPreview(false)}/>
        </React.Fragment>
    );
}