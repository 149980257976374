import React from "react";
import {Grid, Button, Chip, Box, IconButton} from "@mui/joy";
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";
import {DynamicTable} from "../components/DynamicTable";
import {AssignToDialog} from "../dialog/AssignToDialog";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import socket from "../socket/socket";
import Tooltip from "@mui/joy/Tooltip";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";

export const UnpaidDown = ({debouncedValue}) => {
    const navigate = useNavigate()
    const token = localStorage.getItem("token")
    const [unpaid, setUnpaid] = React.useState({rows: [], total: 0});
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [success, setSuccess] = React.useState({
        unpaid: false,
    });
    const [pagination, setPagination] = React.useState({
        unpaid: {
            page: 0,
            limit: 15,
            total: 0
        },
    })

    const handlePagination = (key, page, limit) => {
        setPagination(prevState => {
            const newObje = {...prevState}
            newObje[key] = {...newObje[key], page, limit}
            return newObje;
        })
    }
    const copyToClipboard = (text) => {
        const textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        enqueueSnackbar(`${text} Copied`, {variant: "success"})
        textField.remove()
    }
    const columns = [
        {id: 'id', label: 'ID', align: 'center', minWidth: 70},
        {id: 'name', label: 'Name', align: 'center', minWidth: 70},
        {
            id: 'mobile',
            label: 'Mobile',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return (<Box sx={{cursor: "pointer"}} onClick={(e) => {
                        e.stopPropagation()
                        copyToClipboard(params?.mobile)
                    }}>
                        {params?.mobile}
                    </Box>
                )
            }
        },
        {
            id: 'assign_to_name',
            label: 'AssignTo',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <Box>
                        {params?.assign_to_name ?
                            <Chip sx={{
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} variant="outlined" color="primary">{params?.assign_to_name}</Chip> :
                            <Tooltip placement="left" size="sm" title="AssignTo" color="warning">
                                <IconButton variant="solid" color="primary" disabled={!!params?.assign_to_id} size="sm"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setSelectedRow([params.id])
                                                setOpen(params.payment_status)
                                            }}><AirlineStopsIcon/>
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                )
            }
        },
        {
            id: 'lead_status',
            label: 'Status',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return <Box>
                    {params.lead_status && <Chip
                        variant="outlined"
                        sx={{borderRadius: "7px"}}
                        color={params.lead_status === "completed" ? "success" : "neutral"}
                    >
                        {params.lead_status}
                    </Chip> || "NA"}
                </Box>
            }
        },
        {
            id: 'visit',
            label: 'Visit',
            minWidth: 50,
            align: 'center',
            renderCell: (params) => {
                return <Chip
                    variant="outlined"
                >
                    {params.visit}
                </Chip>
            }
        },
        {
            id: 'createdAt',
            label: 'Created At',
            minWidth: 100,
            align: 'center',
            renderCell: (parms) => {
                // console.log(parms?.createdAt)
                return <Box>{dayjs(`${parms?.createdAt}`).format("YYYY-MM-DD hh:mm")}</Box>
            }
        },
        {
            id: 'amount',
            label: 'Amount',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <Box>{params?.amount ?? "NA"}</Box>
                )
            }
        },
    ];

    let config = {headers: {'Authorization': 'Bearer ' + token}};
    const leads = async () => {
        setSuccess({...success, unpaid: true})
        try {
            let {status, data: {data, type}} = await axios.get(`${WEB_API}admin/retrieve/unpaid-leads?mobile=${debouncedValue}&visitType=down&limit=${pagination.unpaid.limit}&page=${pagination.unpaid.page}`, config);
            if (status === 200 && type === "success") {
                setUnpaid({rows: data?.leads, total: data?.total})
                setSuccess({...success, unpaid: false})
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
            setSuccess({...success, unpaid: false})
        }
    };

    React.useEffect(() => {
        socket.on("lead", (data) => {
            if (data?.pa_status === "Non Paid") {
                leads()
            }
        })

        return () => {
            socket.off("lead")
        }
    }, [])

    React.useEffect(() => {
        leads()
    }, [debouncedValue, pagination.unpaid.limit, pagination.unpaid.page]);

    return (
        <Box sx={{flex: 1, width: '100%'}}>
            <Grid container spacing={2}>
                <Grid xs={12} md={12}>
                    <DynamicTable
                        columns={columns}
                        count={unpaid?.total}
                        rows={unpaid?.rows}
                        perPage={pagination.unpaid.limit}
                        success={!success.unpaid}
                        checkBox={!!unpaid?.rows?.length}
                        hadName={"UnpaidDown"}
                        showHad={true}
                        type={"unpaid"}
                        setPagination={handlePagination}
                        rerender={(status) => {
                            status === "UnpaidDown" && leads()
                        }}
                    />
                </Grid>
            </Grid>
            {open &&
            <AssignToDialog onClose={() => setOpen(false)} open={open} selectedRows={selectedRow} rerender={leads}/>}
        </Box>
    )
}