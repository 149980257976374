// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
  height: 5px;
}
::-webkit-scrollbar-track {
  background: rgba(226, 226, 226, 0.02);        /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #afafb3;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid rgba(226, 226, 226, 0.02);  /* creates padding around scroll thumb */
}`, "",{"version":3,"sources":["webpack://./admin_panel/src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;;AAGA;EACE,UAAU,gBAAgB,kCAAkC;EAC5D,WAAW;AACb;AACA;EACE,qCAAqC,SAAS,+BAA+B;AAC/E;AACA;EACE,yBAAyB,KAAK,8BAA8B;EAC5D,mBAAmB,QAAQ,kCAAkC;EAC7D,2CAA2C,GAAG,wCAAwC;AACxF","sourcesContent":["*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n\n::-webkit-scrollbar {\n  width: 5px;               /* width of the entire scrollbar */\n  height: 5px;\n}\n::-webkit-scrollbar-track {\n  background: rgba(226, 226, 226, 0.02);        /* color of the tracking area */\n}\n::-webkit-scrollbar-thumb {\n  background-color: #afafb3;    /* color of the scroll thumb */\n  border-radius: 20px;       /* roundness of the scroll thumb */\n  border: 1px solid rgba(226, 226, 226, 0.02);  /* creates padding around scroll thumb */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
