import * as React from 'react';
import {
    Input,
    IconButton,
    Sheet,
    Box,
    FormLabel,
    FormControl,
    ModalDialog,
    Modal,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Button,
    FormHelperText
} from "@mui/joy";
import {Visibility, VisibilityOff, Info} from "@mui/icons-material";
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";
import socket from "../socket/socket";

export default function ForgotPasswordDialog({open, onClose}) {
    const [otpVerify, setOtpVerify] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [values, setValues] = React.useState({})
    const [isError, setIsError] = React.useState(false)
    const handleChange = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        })
        if (name === "confirm_password") {
            if (value === values?.password) setIsError(false)
            else setIsError(true)
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            let {status, data: {success, message}} = await axios.post(`${WEB_API}/forgotpassword`, {...values,type:"forgot"});
            if (status === 200 && success) {
                enqueueSnackbar(message, {variant: 'success'})
                setIsLoading(false)
            } else {
                enqueueSnackbar(message, {variant: 'error'})
                setIsLoading(false)
            }
        } catch (e) {
            setIsLoading(false)
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
        }
    };
    const sendOtp = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            let {status, data: {success, message}} = await axios.post(`${WEB_API}/otp`, {...values,type:"forgot"});
            if (status === 200 && success) {
                enqueueSnackbar(message, {variant: 'success'})
                setIsLoading(false)
                setOtpVerify(true)
            }
        } catch (e) {
            setIsLoading(false)
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
        }
    };
    return (
        <React.Fragment>
            <Modal open={open}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        Forgot your password
                    </DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <form
                            onSubmit={(e)=> {!otpVerify ?  sendOtp(e) : handleSubmit(e)}}
                            id="isValid"
                            style={{
                                py: 1,
                                px: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 5,
                                borderRadius: 'sm',
                            }}
                        >
                            <FormControl required>
                                <FormLabel>Email Id</FormLabel>
                                <Input
                                    onChange={(e) => handleChange(e)}
                                    name="email"
                                    type="email"
                                    placeholder="Enter Your Register Email"
                                />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>New Password</FormLabel>
                                <Input
                                    onChange={(e) => handleChange(e)}
                                    name="password"
                                    type="password"
                                    placeholder="New Password"
                                />
                            </FormControl>
                            <FormControl error={isError} required>
                                <FormLabel>Confirm Password</FormLabel>
                                <Input
                                    onChange={(e) => handleChange(e)}
                                    endDecorator={<IconButton>
                                        {showPassword ?
                                            <Visibility sx={{cursor: "pointer"}}
                                                        onClick={() => setShowPassword(false)}/> :
                                            <VisibilityOff sx={{cursor: "pointer"}}
                                                           onClick={() => setShowPassword(true)}/>}
                                    </IconButton>}
                                    name="confirm_password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Confirm password"
                                />
                                {isError ? <FormHelperText>
                                    <Info/>
                                    Opps! Password does't Match.
                                </FormHelperText> : null}
                            </FormControl>
                            {otpVerify ?
                                <>
                                    <FormControl required={otpVerify}>
                                        <FormLabel>Enter OTP</FormLabel>
                                        <Input
                                            onChange={(e) => handleChange(e)}
                                            name="otp"
                                            type="text"
                                            placeholder="Enter Your 6 Digit OTP"
                                        />
                                    </FormControl>
                                </> : null
                            }
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button form="isValid" type="submit" variant="soft" loading={isLoading}
                                disabled={values?.confirm_password !== values?.password}>{otpVerify ? "Verify Now" : "Next"}</Button>
                        <Button variant="soft" color="danger" onClick={() => {
                            onClose();
                            setOtpVerify(false);
                            setIsError(false);
                            setValues({});
                            setShowPassword(false)
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}