import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import {Main} from "../pages/Main";
import {Login} from "../pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import {Dashboard} from "../pages/Dashboard";
import {User} from "../pages/User";
import {PaymentDetails} from "../pages/PaymentDetails";
import {UnpaidUp} from "../pages/UnpaidUp";
import {UnpaidDown} from "../pages/UnpaidDown";
import {Analysis} from "../pages/Analysis";
import {Leads} from "../pages/Leads";


export const Routers = () => {
    const [debouncedValue, setDebouncedValue] = React.useState('')
    return (
        <Routes>
            <Route exect path="/" element={<ProtectedRoute/>}>
                <Route element={<Main setDebouncedValue={setDebouncedValue}/>}>
                    <Route path="/" element={<Navigate replace to="/dashboard"/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/users" element={<User debouncedValue={debouncedValue}/>}/>
                    <Route path="/payment-details" element={<PaymentDetails debouncedValue={debouncedValue}/>}/>
                    <Route path="/leads" element={<Leads debouncedValue={debouncedValue}/>}/>
                    <Route path="/unpaid-up" element={<UnpaidUp debouncedValue={debouncedValue}/>}/>
                    <Route path="/unpaid-down" element={<UnpaidDown debouncedValue={debouncedValue}/>}/>
                    <Route path="/analysis" element={<Analysis debouncedValue={debouncedValue}/>}/>
                </Route>
            </Route>
            <Route exect path="/" element={<PublicRoute/>}>
                <Route path="/sign-in" element={<Login/>}/>
            </Route>
        </Routes>
    )
}